import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="hideable"
export default class extends Controller {
  connect() {
    console.log("hideable connected")
    const notification = document.querySelector(".notice");
    setTimeout(() => {
      notification.style.display = "none";
    }, 3000);
  }
}
