import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("auto submit controller connected")
  }
  
  submit() {
    console.log("asdfasdfasaf");
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.element.requestSubmit()
    }, 250)
  }
}
