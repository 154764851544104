import { Controller } from "@hotwired/stimulus"

import SlimSelect from "slim-select"

// Connects to data-controller="slim"
export default class extends Controller {
  static targets = ["field"]
  connect() {
    console.log('Slim Controller Connected');
    new SlimSelect({
      select: this.fieldTarget
    })
  }
}
