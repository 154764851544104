import { Controller } from "@hotwired/stimulus";


export default class extends Controller {
  static targets = ["qrReader"]
  connect() {
    let html5QrcodeScanner = new Html5QrcodeScanner(
      this.qrReaderTarget.id, { fps: 10, qrbox: 250 }
    )
    html5QrcodeScanner.render(this.onScanSuccess);
  }

  onScanSuccess(decodedText, decodedResult) {
    window.location.href = decodedText;
  }
}