import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["dropzone", "filesList", "fileInput"];

  connect() {
    this.files = [];
    this.setupEventListeners();
  }

  setupEventListeners() {
    [
      "drag",
      "dragstart",
      "dragend",
      "dragover",
      "dragenter",
      "dragleave",
      "drop",
    ].forEach((eventName) => {
      this.dropzoneTarget.addEventListener(
        eventName,
        this.preventDefaults.bind(this),
        false,
      );
      if (["dragover", "dragenter"].indexOf(eventName) > 0) {
        this.dropzoneTarget.addEventListener(
          eventName,
          this.highlight.bind(this),
          false,
        );
      }
      if (["dragleave", "dragend", "drop"].indexOf(eventName) > 0) {
        this.dropzoneTarget.addEventListener(
          eventName,
          this.unhighlight.bind(this),
          false,
        );
      }
    });

    this.dropzoneTarget.addEventListener(
      "drop",
      this.handleDrop.bind(this),
      false,
    );
  }

  selectFiles() {
    this.fileInputTarget.click();
  }

  preventDefaults(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  highlight() {
    this.dropzoneTarget.classList.add("is-dragover");
  }

  unhighlight() {
    this.dropzoneTarget.classList.remove("is-dragover");
  }

  handleDrop(e) {
    let dt = e.dataTransfer;
    let droppedFiles = dt.files;
    this.files = [...droppedFiles];
    this.handleFiles(this.files);
    this.fileInputTarget.files = dt.files;
  }

  handleFiles(files) {
    files.forEach(this.uploadFile.bind(this));
  }

  uploadFile(file) {
    let fileDiv = document.createElement("div");
    fileDiv.className = "d-flex justify-content-between align-items-center";
    fileDiv.style =
      "margin-top: 10px;border: dashed 1px black;padding: 10px;gap: 20px";
    fileDiv.innerHTML = `
      <div class="d-flex align-items-center">
        <div class="d-flex flex-column gap-1">
          <span class="file-name" style='font-size:12px'>${file.name}</span>
        </div>
      </div>
      <div>
        <img src="/assets/icons/trash-a18e02024cbc385ac75ade0e0506a42057b3fede15bf8bfa8e3d8e74be6776e3.svg" class="remove-img" style="height:20px;cursor:pointer" data-action="click->file-upload#removeFile">
      </div>
    `;
    this.filesListTarget.appendChild(fileDiv);
  }

  removeFile(e) {
    let fileDiv = e.target.closest(".d-flex");
    let fileName = fileDiv.querySelector(".file-name").textContent;
    let index = this.files.findIndex((f) => f.name === fileName);
    if (index !== -1) {
      this.files.splice(index, 1);
      fileDiv.remove();
    }
  }

  handleFileInputChange(e) {
    this.files = [...e.target.files];
    this.filesListTarget.innerHTML = "";
    this.files.forEach(this.uploadFile.bind(this));
  }

  humanFileSize(size) {
    var i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
    return (
      (size / Math.pow(1024, i)).toFixed(2) * 1 +
      " " +
      ["B", "kB", "MB", "GB", "TB"][i]
    );
  }
}
